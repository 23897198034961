import { Stack, Grid, Box, Divider, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useRef } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { LessonsSlideList } from './components/LessonsSlideList';
import { LessonsTitle } from './components/LessonsTitle';
import { LessonsDelete } from './LessonsDelete';
import { LessonsSlide } from './components/LessonsSlide';
import { LessonsAddProps } from './components/LessonsAddProps';

import { LessonsSlideAddPopup } from './components/dataComponents/adds/LessonsSlideAddPopup';
import { SlidePreviewContextProvider } from './contexts/SlidePreviewContext';

import { LessonsSharePopup } from './components/LessonsSharePopup';
import { LessonsPublishButton } from './components/LessonsPublishButton';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { UserPermissionContext } from '../../contexts/UserPermissionContext';
import { useLessonAddExportSlidesToPDF } from './hooks/useLessonAddExportSlidesToPDF';
import { useLessonHook } from './hooks/useLessonHook';
import { LessonsSlideAddPopupDropdown } from './components/dataComponents/adds/LessonsSlideAddPopupDropdown';
import { PublishButton } from './newClassroomFlow/PublishButton';

export const LessonsAddPage = () => {
    const { t } = useTranslation();

    const containerRef = useRef<HTMLDivElement>(null);

    const { userPermissions } = useContext(UserPermissionContext);

    const { canEdit, loading, lessonID, lessonData, handleGoBack, isOwner } = useLessonHook();
    const { handleExportSlidesToPDF } = useLessonAddExportSlidesToPDF();

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <PopupCircularProgress isOpened={loading} />
            <Box
                sx={{
                    bgcolor: '#FFFBF6',
                    boxShadow: 4,
                    borderRadius: '24px',
                    display: 'flex',
                    height: '100vh',
                    flexDirection: 'column',
                }}>
                <Box>
                    <Grid container>
                        <Grid item xs={10} sx={{ pt: 2 }}>
                            <Box justifyContent='center' textAlign='center'>
                                <LessonsTitle disabled={!canEdit} lessonID={lessonID ?? ''} value={lessonData?.latestLessonDraft?.lessonName ?? ''} />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}>
                            {process.env.REACT_APP_CLASSROOM_FLOW_ENABLED === 'true' ? (
                                <PublishButton lessonID={lessonID ?? ''} lessonVersionID={lessonData?.latestLessonDraft.id} />
                            ) : (
                                <LessonsPublishButton lessonID={lessonID ?? ''} lessonVersionID={lessonData?.latestLessonDraft.id} lessonName={lessonData?.latestLessonDraft?.lessonName ?? ''} />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container sx={{ p: 2, mt: -1 }}>
                        <Grid item xs={6}>
                            <Button
                                onClick={handleGoBack}
                                variant='contained'
                                color='primary'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                }}
                                startIcon={<ArrowBackIosIcon />}>
                                {t('overview-of-lessons')}
                            </Button>
                        </Grid>
                        <Grid item container justifyContent='flex-end' xs={6}>
                            {lessonID ? <LessonsDelete disabled={!isOwner} lessonID={lessonID} /> : ''}
                            <Button
                                variant='outlined'
                                color='secondary'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '0px',
                                    ml: 1,
                                }}
                                startIcon={<PresentToAllIcon />}
                                href={`/preview/${lessonID}`}>
                                {t('preview')}
                            </Button>

                            {lessonID && (
                                <LessonsSharePopup disabled={!isOwner} lessonID={lessonID} title={lessonData?.latestLessonDraft?.lessonName} shareWithPublic={lessonData?.shareWithPublic ?? false} />
                            )}

                            <Button
                                onClick={handleExportSlidesToPDF}
                                variant='outlined'
                                color='inherit'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '0px',
                                    ml: 1,
                                }}
                                startIcon={<FileDownloadIcon />}>
                                {t('export-pdf')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='center' xs={12} sx={{ mt: -1, pb: 1 }}>
                        <Box>
                            <LessonsSlideAddPopup disabled={!canEdit} type='background' text={t('background-box')} />
                            <LessonsSlideAddPopup disabled={!canEdit} type='text' text={t('text-box')} />
                            <LessonsSlideAddPopup disabled={!canEdit} type='character' text={t('character-box')} />
                            <LessonsSlideAddPopup disabled={!canEdit} type='audio' text={t('audio-box')} />
                            <LessonsSlideAddPopup disabled={!canEdit} type='h5p' text={t('h5p.h5pTitle')} fullWidth={true} maxWidth={'xl'} />
                            <LessonsSlideAddPopupDropdown disabled={!canEdit} text={t('hkcs.title')}>
                                <Stack>
                                    <LessonsSlideAddPopup disabled={!canEdit} type='hkcsQ1' text={t('hkcs.q1.title')} hasPermission={userPermissions?.enableHkcsQ1} />
                                    <LessonsSlideAddPopup disabled={!canEdit} type='hkcsQ2' text={t('hkcs.q2.title')} hasPermission={userPermissions?.enableHkcsQ2} />
                                    <LessonsSlideAddPopup disabled={!canEdit} type='hkcsQ3' text={t('hkcs.q3.title')} hasPermission={userPermissions?.enableHkcsQ3} maxWidth={'xl'} />
                                    <LessonsSlideAddPopup disabled={!canEdit} type='hkcsEnQ1' text={t('hkcs.en.q1.title')} hasPermission={userPermissions?.enableHkcsEnQ1} />
                                    <LessonsSlideAddPopup disabled={!canEdit} type='hkcsEnQ2' text={t('hkcs.en.q2.title')} hasPermission={userPermissions?.enableHkcsEnQ2} />
                                </Stack>
                            </LessonsSlideAddPopupDropdown>
                            <LessonsSlideAddPopupDropdown disabled={!canEdit} text={t('hksas.title')}>
                                <Stack>
                                    <LessonsSlideAddPopup disabled={!canEdit} type='multipleChoice' text={t('multipleChoice')} hasPermission={userPermissions?.enableMultipleChoice} />
                                    <LessonsSlideAddPopup disabled={!canEdit} type='sasAudio' text={t('hksas.audio.title')} hasPermission={userPermissions?.enableSasAudio} />
                                    <LessonsSlideAddPopup
                                        disabled={!canEdit}
                                        type='sasFillInTheBlanks'
                                        text={t('hksas.fillInTheBlanks.title')}
                                        hasPermission={userPermissions?.enableSasFillInTheBlanks}
                                    />
                                    <LessonsSlideAddPopup
                                        disabled={!canEdit}
                                        type='sasFillInTheBlanksWriting'
                                        text={t('hksas.fillInTheBlanksWriting.title')}
                                        hasPermission={userPermissions?.enableSasFillInTheBlanksWriting}
                                    />
                                    <LessonsSlideAddPopup
                                        disabled={!canEdit}
                                        type='sasRadicalMatching'
                                        text={t('hksas.radicalMatching.title')}
                                        hasPermission={userPermissions?.enableSasRadicalMatching}
                                        fullWidth={true}
                                        maxWidth={'md'}
                                    />
                                </Stack>
                            </LessonsSlideAddPopupDropdown>
                        </Box>
                    </Grid>
                </Box>

                <Divider sx={{ border: 1, borderColor: '#68522B' }} />

                <Grid
                    container
                    sx={{
                        height: '100%',
                    }}>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <LessonsSlideList lessonID={lessonID ?? ''} lessonVersionID={lessonData?.latestLessonDraft?.id} slideData={lessonData?.latestLessonDraft?.slides} />
                    </Grid>
                    <SlidePreviewContextProvider>
                        <Grid ref={containerRef} item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ pl: 1, position: 'relative' }}>
                            <LessonsSlide observeTarget={containerRef} />
                        </Grid>
                    </SlidePreviewContextProvider>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <LessonsAddProps />
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
};
